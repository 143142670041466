<template>
    <base-page>
        <titlu-pagina Titlu="Planuri pe arii" :AdaugaVisible='false'  />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">

                       <el-col :span='4'>
                            <el-form-item label='Nume arie' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Cod arie' >
                                <el-input v-model='Filters.Cod' />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Descriere' >
                                <el-input v-model='Filters.Descriere' />
                            </el-form-item>
                        </el-col>                         
                        <el-col :span='4'>
                            <el-form-item label='Plan de management' >
                                <el-select class='full-width' v-model='Filters.IdPlanDeManagement' filterable>
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='Fara' value='0'></el-option>
                                    <el-option v-for='item in Info.planuri_management' :key="'planuri_management' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                          
                        <el-col :span='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' > Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column label='Nume'>
                 <template slot-scope="scope">
                    <div>{{scope.row.Nume }} ({{scope.row.Cod}})</div>
                </template>  
            </el-table-column>
            <el-table-column prop='PlanManagement' label='Plan Management'/>
            <el-table-column label='Versiune'>
                <template slot-scope="scope">
                    <div >VERSIUNE {{scope.row.Id}}</div>
                </template>  
            </el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="300px" >
                <template slot-scope="scope" >
                    <el-button type="success" icon="el-icon-download"  circle @click="export_pdf(scope.row.Id)" />
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Utilizatori-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from "@/pages/BasePage";
    import Utilizatori_dialog from '@/pages/utilizatori/Utilizatori_dialog.vue';
    import TitluPagina from '@/widgets/TitluPagina';

    export default {
        name: "utilizatori",
        extends: BasePage,
        components: {
            'base-page': BasePage,
            'Utilizatori-dialog': Utilizatori_dialog,
            'titlu-pagina': TitluPagina
        },
        data () {
            return {
                Results: [],
                base_url: '',
                Info: { planuri_management:[] },   
                Filters: {Nume: '' , Cod:'',Descriere:'', IdPlanDeManagement:''},      
                OrderBy: { },
                PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
                
            }
        },
        methods: {

            async get_info(){
                var response            = await this.post("raport_planuri_pe_arii/get_info" );
                this.Info.planuri_management = response.Planuri_management         
                this.refresh_info();
            },

            async refresh_info(){
                var response        = await this.post("raport_planuri_pe_arii/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
                this.Results        = response.Results;
                this.PaginationInfo = response.PaginationInfo;
                //
                // this.select_menu_item('utilizatori');
            },
            reset(){
                this.Filters = {Nume: '' , Cod:'', Descriere:'', IdPlanDeManagement:''};
                this.refresh_info();
            },
            async export_pdf(idArie){
                //var token    = settings.get_token();
                //var filters  = encodeURI(JSON.stringify(this.Filters) );            
                // window.open(`${this.base_url}inventar/export_excel/${token}/${idInventar}/${filters}`);
                
                //window.open(`${this.base_url}inventar/export_excel/${idInventar}`);

                alert("Download PDF " + idArie);
                
            },
        },
        mounted(){
            this.base_url = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
